<!-- 商家端 - 主体信息 -->
<template>
  <div class="contractInfo">
      <div class="title">
        <strong class="form-title__text">{{ title }}</strong>
      </div>
      <!--主体信息-->
      <div class="form-title">
        <strong class="form-title__text">合同主体信息</strong>
      </div>
      <p class="tips-title">主体信息需要保证唯一性，不允许重复</p>
      <el-form :model="contractInfoForm" :rules="contractInfoRules" ref="contractInfoForm" label-width="150px" label-position="left">
        <el-row type="flex" style="flex-wrap: wrap">
          <el-col :span="12">
            <el-form-item label="个人/企业" prop="merchantType">
              <el-radio-group v-model="contractInfoForm.merchantType" @input="merchantTypeInput" :disabled="!!id">
                <el-radio label="ENTERPRISE">企业</el-radio>
                <el-radio label="PERSONAL">个人</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <template v-if="contractInfoForm.merchantType === 'ENTERPRISE'">
            <el-col :span="12">
              <el-form-item label="公司类型" prop="companyType">
                <el-radio-group v-model="contractInfoForm.companyType" :disabled="isEditForm">
                  <el-radio label="MAINLAND_COMPANY">大陆企业</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="企业名称" prop="company">
                <el-input v-model.trim="contractInfoForm.company" placeholder="请输入" :disabled="isEditForm && !!cloneDeepContractInfoForm.company" style="margin-right: 12px"></el-input>
                <el-button v-if="contractInfoForm.companyType === 'MAINLAND_COMPANY'" :loading="getTaxNoLoading" @click="getTaxNo" type="primary" :disabled="isEditForm && !!cloneDeepContractInfoForm.company">获取税号</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="税号" prop="taxNo" :rules="contractInfoForm.companyType === 'MAINLAND_COMPANY' ? [{ required: true, message: '必填信息', trigger: 'blur' }] : []">
                <el-input v-model.trim="contractInfoForm.taxNo" disabled placeholder="请点击获取税号"></el-input>
              </el-form-item>
            </el-col>
          </template>
          <el-col :span="12">
            <el-form-item :label="contractInfoForm.merchantType === 'PERSONAL' ? '个人类型' : '法定代表人类型'" prop="personType">
              <el-select v-model="contractInfoForm.personType" :disabled="isEditForm">
                <el-option :key="idx" :label="item.label" :value="item.value" v-for="(item, idx) in personTypeList"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="contractInfoForm.merchantType === 'PERSONAL' ? '联系人真实姓名' : '法定代表人姓名'" prop="contactRealName">
              <el-input v-model.trim="contractInfoForm.contactRealName" :disabled="isEditForm && !!cloneDeepContractInfoForm.contactRealName" placeholder="请输入" @input="contactRealNameInput"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="contactIdcardNoName" prop="contactIdcardNo">
              <el-input v-model.trim="contractInfoForm.contactIdcardNo" :disabled="isEditForm && !!cloneDeepContractInfoForm.contactIdcardNo" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="contractInfoForm.merchantType === 'PERSONAL' ? '联系人电话' : '法定代表人联系电话'" prop="contactPhone">
              <el-input v-model.trim="contractInfoForm.contactPhone" :disabled="isEditForm && !!cloneDeepContractInfoForm.contactPhone" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系人地址" prop="contactAddress">
              <el-input v-model.trim="contractInfoForm.contactAddress" :disabled="isEditForm && !!cloneDeepContractInfoForm.contactAddress" placeholder="请输入" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系人邮箱" prop="contactEmail">
              <el-input v-model.trim="contractInfoForm.contactEmail" placeholder="请输入" @input="contactEmailInput" :disabled="isEditForm && !!cloneDeepContractInfoForm.contactEmail"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12"  v-if="contractInfoForm.merchantType === 'ENTERPRISE'">
            <el-form-item label="上传营业执照" prop="attachmentJson">
              <PictureCardCrypto :limit="2" :maxSize="2000 * 1024" :disabled="isEditForm && !!cloneDeepContractInfoForm.attachmentJson.length" v-model="contractInfoForm.attachmentJson"></PictureCardCrypto>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="cardUrlName" prop="cardUrls">
              <PictureCardCrypto :limit="2" :maxSize="2000 * 1024" :disabled="isEditForm && !!cloneDeepContractInfoForm.cardUrls.length" v-model="contractInfoForm.cardUrls"></PictureCardCrypto>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 发票主体信息 -->
      <div class="form-title">
        <strong class="form-title__text">发票主体信息</strong>
      </div>
      <p class="tips-title tips-title--color">发票的主体必须跟合作的主体保持一致</p>
      <el-form :model="invoiceInfoForm" :rules="invoiceInfoRules" ref="invoiceInfoForm" label-width="120px" label-position="left">
        <el-row type="flex" style="flex-wrap: wrap">
          <el-col :span="12">
            <el-form-item label="发票抬头" prop="title">
              <el-input v-model.trim="invoiceInfoForm.title" :placeholder="contractInfoForm.merchantType === 'ENTERPRISE' ? '合同主体名称' : ''" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <template v-if="contractInfoForm.merchantType === 'ENTERPRISE'">
            <el-col :span="12">
              <el-form-item label="税号" prop="taxNo" :rules="contractInfoForm.companyType === 'MAINLAND_COMPANY' ? [{ required: true, message: '必填信息', trigger: 'blur' }] : []">
                <el-input v-model.trim="invoiceInfoForm.taxNo" placeholder="合同税号" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="开户银行" prop="bankName">
                <el-input v-model.trim="invoiceInfoForm.bankName" type="textarea" :autosize="{ minRows: 1, maxRows: 2 }" placeholder="请输入" :disabled="isEditForm && !!cloneDeepInvoiceInfoForm.bankName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="银行账号" prop="bankAccount">
                <el-input v-model.trim="invoiceInfoForm.bankAccount" placeholder="请输入" :disabled="isEditForm && !!cloneDeepInvoiceInfoForm.bankAccount" maxlength="30"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="企业地址" prop="address">
                <el-input v-model.trim="invoiceInfoForm.address" type="textarea" :autosize="{ minRows: 1, maxRows: 2 }" placeholder="请输入" :disabled="isEditForm && !!cloneDeepInvoiceInfoForm.address"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="企业电话" prop="mobile">
                <el-input v-model.trim="invoiceInfoForm.mobile" placeholder="请输入" :disabled="isEditForm && !!cloneDeepInvoiceInfoForm.mobile"></el-input>
              </el-form-item>
            </el-col>
          </template>
          <el-col :span="24">
            <p style="margin-bottom: 12px" class="tips-title--color">发票联系人信息（用于发票邮寄和发送使用）</p>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系人姓名" prop="contactName">
              <el-input v-model.trim="invoiceInfoForm.contactName" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系人地址" prop="areaCodes">
              <el-row>
                <el-col :span="24" style="display: flex;">
                  <AreaCascader class="area-width" placeholder="联系人地址" v-model="invoiceInfoForm.areaCodes" />
                  <el-input v-model.trim="invoiceInfoForm.addressDetail" placeholder="请输入"></el-input>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系人电话" prop="contactPhone">
              <el-input v-model.trim="invoiceInfoForm.contactPhone" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系人邮箱" prop="email">
              <el-input v-model.trim="invoiceInfoForm.email" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 收付款账号信息 -->
      <div class="form-title">
        <strong class="form-title__text">收付款账号信息</strong>
        <template v-if="!isEditInfo || !bankCardInfoList.length">
          <button-hoc size="mini" type="primary" plain @click="addBankCard" v-if="!isAddBankCardInfo">新增账号</button-hoc>
          <template v-if="isAddBankCardInfo">
            <button-hoc size="mini" type="primary" plain @click="cancelBankCardInfo">取消新增</button-hoc>
            <button-hoc size="mini" type="primary" @click="submitBankCardInfo" :loading="editBankCardInfoLoading">保 存</button-hoc>
          </template>
        </template>
      </div>
      <el-form v-show="(!isEditInfo || !bankCardInfoList.length) && isAddBankCardInfo" :model="bankCardInfoForm" :rules="bankCardInfoRules" ref="bankCardInfoForm" label-width="120px" label-position="left">
        <el-row type="flex" style="flex-wrap: wrap">
          <el-col :span="12">
            <el-form-item label="开户行" prop="bankName">
              <el-input v-model.trim="bankCardInfoForm.bankName" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开户名" prop="cardholder">
              <el-input v-model.trim="bankCardInfoForm.cardholder" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="账号" prop="cardNumber">
              <el-input v-model.trim="bankCardInfoForm.cardNumber" maxlength="30" show-word-limit placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联行号" prop="bankCode">
              <el-input v-model.trim="bankCardInfoForm.bankCode" placeholder="请输入" maxlength="20"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="attachmentJsonList">
              <ul slot="label" class="custom-label">
                <li>开户行许可证/</li>
                <li>收款银行确认函</li>
              </ul>
              <PictureCardCrypto accept=".doc,.docx,.pdf,.png,.jpeg,.jpg" :limit="2" :maxSize="2000 * 1024" v-model="bankCardInfoForm.attachmentJsonList" :isHhow="false"></PictureCardCrypto>
              <div class="tip">最多上传2张,最大2M</div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 编辑/新增收付款账号信息 -->
      <accountInformation  ref="accountCardTable" :list="bankCardInfoList" :disabledEdit="isEditForm" v-if="isEditInfo && bankCardInfoList.length" />
      <!-- 选择收付款账号信息 -->
      <paymentAccount ref="paymentAccount" :list="paymentList" v-if="(!isEditInfo || !bankCardInfoList.length) && !isAddBankCardInfo" @click="handeSelect" />
      <!-- 填写说明 -->
      <illustrateExplain v-show="(!isEditInfo || !bankCardInfoList.length) && isAddBankCardInfo" />
      <div slot="footer" class="dialog-footer">
        <div class="dialog-footer__box-wrap">
          <el-checkbox v-model="isAgree">
              <span class="dialog-footer__textbox">已阅读并同意</span></el-checkbox
            >
            <span class="link-type dialog-footer__title" @click="dialogVisibleProtocol = true">《免责声明》</span>
        </div>
        <el-button @click="backPage" size="medium">取 消</el-button>
        <button-hoc size="medium" type="primary" @click="submitForm" :loading="formLoading">保 存</button-hoc>
      </div>
      <el-dialog
      title="免责声明"
      :visible.sync="dialogVisibleProtocol"
      top="10vh"
      :before-close="() => (dialogVisibleProtocol = false)"
    >
      <div class="textBox">
        <agreement agreementType="CREDIT_AGREEMENT" />
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { createInfo, getlistForApps, createBankCard, updateInfo, getContractInfo } from '@/api/subject-information';
import { dictListByType, queryByName } from '@/api/invoice';
import { validatePhoneLoose, validateEmail } from '@/utils/validate';
import PictureCardCrypto from '@/components/Upload/PictureCardCrypto';
import AreaCascader from '@/components/AreaCascader';
import accountInformation from './components/account-information';
import paymentAccount from './components/payment-account';
import illustrateExplain from '@/components/illustrate-explain';
import cloneDeep from 'lodash/cloneDeep';
import { mapGetters } from 'vuex';
import agreement from '@/views/agreement/index.vue';
export default {
  name: 'contractInfo',
  components: {
    PictureCardCrypto,
    accountInformation,
    AreaCascader,
    paymentAccount,
    illustrateExplain,
    agreement
  },
  data() {
    return {
      dialogVisibleProtocol: false,
      isAgree: false,
      id: '', // 主体信息ID
      distributorId: '', // 分销商ID
      paymentList: [],
      distribution: {},
      contractInfoForm: {
        merchantType: 'ENTERPRISE',
        companyType: 'MAINLAND_COMPANY',
        personType: 'MAINLAND_PERSON',
        company: '',
        taxNo: '',
        contactRealName: '',
        contactIdcardNo: '',
        contactAddress: '',
        contactPhone: '',
        contactEmail: '',
        attachmentJson: [],
        cardUrls: [],
      },
      invoiceInfoForm: {
        title: '',
        taxNo: '',
        bankName: '',
        bankAccount: '',
        mobile: '',
        contactName: '',
        contactPhone: '',
        email: '',
        address: '',
        areaCodes: [],
        addressDetail: '',
      },
      bankCardInfoForm: {
        bankName: '',
        cardholder: '',
        cardNumber: '',
        bankCode: '',
        attachmentJsonList: [],
      },
      cloneDeepContractInfoForm: {
        attachmentJson: [],
        cardUrls: []
      },
      cloneDeepInvoiceInfoForm: {},
      distributorBankCardIdList: [],
      bankCardInfoList: [], // 收付款账号信息列表
      contractInfoRules: {
        company: [{ required: true, message: '必填信息', trigger: 'blur' }],
        contactRealName: [{ required: true, message: '必填信息', trigger: 'blur' }],
        contactIdcardNo: [{ required: true, message: '必填信息', trigger: 'blur' }],
        contactAddress: [{ required: true, message: '必填信息', trigger: 'blur' }],
        personType: [{ required: true, message: '必填信息', trigger: 'change' }],
        contactPhone: [{ required: true, validator: validatePhoneLoose, trigger: 'blur' }],
        contactEmail: [{ required: true, validator: validateEmail, trigger: 'blur' }],
        attachmentJson: [
          {
            required: true,
            validator: (_, value, callback) => {
              if (value.length) {
                callback();
              } else {
                callback(new Error('请上传营业执照'));
              }
            },
            trigger: 'change'
          }
        ],
        cardUrls: [
          {
            required: true,
            validator: (_, value, callback) => {
              if (value.length) {
                callback();
              } else {
                const before = this.contractInfoForm.merchantType === 'ENTERPRISE' ? '请上传' : '请';
                callback(new Error(`${before}${this.cardUrlName}`));
              }
            },
            trigger: 'change'
          }
        ]
      },
      invoiceInfoRules: {
        title: [{ required: true, message: '必填信息', trigger: 'blur' }],
        bankName: [{ required: true, message: '必填信息', trigger: 'blur' }],
        bankAccount: [{ required: true, message: '必填信息', trigger: 'blur' }],
        mobile: [{ required: true, message: '必填信息', trigger: 'blur' }],
        contactName: [{ required: true, message: '必填信息', trigger: 'blur' }],
        contactPhone: [{ required: true, validator: validatePhoneLoose, trigger: 'blur' }],
        email: [{ required: true, validator: validateEmail, trigger: 'blur' }],
        address: [{ required: true, message: '必填信息', trigger: 'blur' }],
        areaCodes: [
          {
            required: true,
            validator: (_, value, callback) => {
              if (value.length && this.invoiceInfoForm.addressDetail) {
                callback();
              } else {
                callback(new Error(`必填信息`));
              }
            },
            trigger: 'blur,change'
          }
        ],
        addressDetail: [{ required: true, message: '必填信息', trigger: 'blur' }]
      },
      bankCardInfoRules: {
        bankName: [{ required: true, message: '必填信息', trigger: 'blur' }],
        cardholder: [{ required: true, message: '必填信息', trigger: 'blur' }],
        cardNumber: [{ required: true, message: '必填信息', trigger: 'blur' }]
      },
      isEditInfo: false, // 是否编辑
      isAddBankCardInfo: false, // 是否新增收付款信息
      getTaxNoLoading: false,
      formLoading: false,
      editBankCardInfoLoading: false,
      personTypeList: [],
    };
  },
  computed: {
    title() {
      return `${this.id ? '编辑' : '新增'}主体信息`;
    },
    contactIdcardNoName() {
      const { merchantType, personType } = this.contractInfoForm;
      const before = merchantType === 'ENTERPRISE' ? '法定代表人' : '联系人';
      const after = personType === 'MAINLAND_PERSON' ? '身份证' : '护照';
      return `${before}${after}`;
    },
    cardUrlName() {
      const { merchantType, personType } = this.contractInfoForm;
      const before = merchantType === 'ENTERPRISE' ? '法定代表人' : '上传';
      const after = personType === 'MAINLAND_PERSON' ? '身份证' : '护照';
      return `${before}${after}`;
    },
    isEditForm() {
      return this.$route.query.isHasContract === '1' && this.isEditInfo;
    },
    ...mapGetters(['userInfo']),
  },
  created() {
    this.getDict();
    this.distributorId = this.userInfo?.distributorId || '';
    this.id = this.$route.query.id;
    this.isEditInfo = !!this.id;
    this.getBankInfoList();
    if(this.id) {
      this.editEnterprise();
    }
    if (window.history && window.history.pushState) {
      // 回退监听事件
      window.addEventListener('popstate', this.backPage, { once: true });
    }
  },
  methods: {
    getDict(){
      dictListByType('distributor_person_type').then(res => {
        this.personTypeList = res.data || [];
      });
    },
    // 选择收付款账户信息
    handeSelect(id) {
      this.paymentList.map(item => {
        if (item.id === id) {
          item.isChecked = !item.isChecked;
        }
      });
      const index = this.distributorBankCardIdList.indexOf(id);
      if (index === -1) {
        this.distributorBankCardIdList.push(id);
      } else {
        this.distributorBankCardIdList.splice(index, 1);
      }
    },
    // 新增-获取收付款账号信息
    getBankInfoList() {
      getlistForApps().then(res => {
        if (res.code === '0') {
          const idMap = new Map(this.paymentList.map(item => [item.id, item]));
          this.paymentList = res.data.map(item =>
            (idMap.get(item.id) || Object.assign(item, { isChecked: this.distributorBankCardIdList.includes(item.id) }))
          );
        }
      });
    },
    // 新增收付款账号信息
    addBankCard() {
      const ref = this.$refs['bankCardInfoForm'];
      ref && ref.resetFields();
      this.isAddBankCardInfo = true;
    },
    // 取消编辑收付款账号信息
    cancelBankCardInfo() {
      this.$refs['bankCardInfoForm'].resetFields();
      this.isAddBankCardInfo = false;
    },
    // 保存收付款账号信息
    submitBankCardInfo() {
      this.checkForm('bankCardInfoForm')
        .then(() => {
          this.editBankCardInfoLoading = true;
          const { bankCardInfoForm, distributorId } = this;
          const params = {
            ...bankCardInfoForm,
            attachmentJsonList: bankCardInfoForm.attachmentJsonList.map((item) => item.url),
          };
          if (this.isAddBankCardInfo) {
            params.distributorId = distributorId;
          }
          createBankCard(params)
            .then(res => {
              this.$message.success('新增成功');
              this.isAddBankCardInfo = false;
              this.handeSelect(res.data);
              this.getBankInfoList();
            })
            .finally(() => {
              this.editBankCardInfoLoading = false;
            });
        })
        .catch(() => {
          this.editBankCardInfoLoading = false;
        });
    },
    // 获取税号
    getTaxNo() {
      this.getTaxNoLoading = true;
      if (this.contractInfoForm.company) {
        queryByName(this.contractInfoForm.company)
          .then((res) => {
            const { taxNo = '', name = '' } = res.data;
            this.contractInfoForm.taxNo = taxNo;
            this.invoiceInfoForm.title = name;
            this.invoiceInfoForm.taxNo = taxNo;
          })
          .finally(() => {
            this.getTaxNoLoading = false;
          });
      } else {
        this.$message({
          message: '请先填写企业名称',
          type: 'warning',
        });
        this.getTaxNoLoading = false;
      }
    },
    // 个人/企业切换
    merchantTypeInput() {
      this.invoiceInfoForm.title = '';
    },
    // 返回
    backPage() {
      this.$router.replace('/syoung/shop/detail?activeName=second');
    },
    // 同步主体信息中的法人姓名
    contactRealNameInput(val) {
      // this.invoiceInfoForm.contactName = val;
      // 当为个人时，发票抬头同步主体信息中的联系人真实姓名
      if (this.contractInfoForm.merchantType === 'PERSONAL') {
        this.invoiceInfoForm.title = val;
      }
    },
    // 同步主体信息中的邮箱
    contactEmailInput(val) {
      if (this.isEditInfo) {
        return;
      }
      this.invoiceInfoForm.email = val;
    },
    // 校验单个表单
    checkForm(formName) {
      return new Promise((resolve, reject) => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            resolve();
          } else {
            reject();
          }
        });
      });
    },
    // 校验全部表单
    checkAllForm() {
      const list = [this.checkForm('contractInfoForm'), this.checkForm('invoiceInfoForm')];
      return Promise.all(list);
    },
    // 编辑企业
    editEnterprise() {
      getContractInfo({
        id: this.id,
      }).then((res) => {
        const { distributorContractInfoVO = {}, distributorBankCardVOList = {}, invoiceTitleVO = {} } = res.data;
        distributorContractInfoVO.attachmentJson = (distributorContractInfoVO?.attachmentJson ?? []).map((item) => ({
          url: item
        }));
        distributorContractInfoVO.cardUrls = (distributorContractInfoVO?.cardUrls ?? []).map((item) => ({
          url: item
        }));
        // 初始化历史数据
        const { title = '', taxNo = '' } = invoiceTitleVO;
        if (!title) {
          invoiceTitleVO.title = distributorContractInfoVO.merchantType === 'ENTERPRISE' ? distributorContractInfoVO.company : distributorContractInfoVO.contactRealName;
        }
        // 当为企业时，发票抬头同步主体信息中的税号
        if (!taxNo && distributorContractInfoVO.merchantType === 'ENTERPRISE') {
          invoiceTitleVO.taxNo = distributorContractInfoVO.taxNo;
        }
        Object.assign(this.contractInfoForm, distributorContractInfoVO);
        Object.assign(this.invoiceInfoForm, invoiceTitleVO);
        this.cloneDeepContractInfoForm = cloneDeep(distributorContractInfoVO);
        this.cloneDeepInvoiceInfoForm = cloneDeep(invoiceTitleVO);
        this.bankCardInfoList = distributorBankCardVOList;
        this.distributorBankCardIdList = distributorBankCardVOList.map(item => item.id);
        this.isEditInfo = true;
      });
    },
    // 提交
    submitForm() {
      if (!this.isAgree) {
        this.$message.info('请先勾选并同意《免责声明》');
        return;
      }
      this.checkAllForm()
        .then(() => {
          const { contractInfoForm, invoiceInfoForm, distributorId, id, distributorBankCardIdList, isAddBankCardInfo } = this;
          if (isAddBankCardInfo) {
            this.$message.error('请先完成收付款账号信息新增操作');
            return;
          }
          if (!distributorBankCardIdList.length && !isAddBankCardInfo) {
            this.$message.error('请新增收付款账号信息或选择已有信息');
            return;
          }
          this.formLoading = true;
          const distributorContractInfoCmd = {
            ...contractInfoForm,
            attachmentJson: contractInfoForm.attachmentJson.map((item) => item.url),
            cardUrls: contractInfoForm.cardUrls.map((item) => item.url),
            distributorId,
          };
          if(contractInfoForm.merchantType === 'PERSONAL'){
            ['company', 'taxNo', 'attachmentJson'].forEach((item) => {
               delete distributorContractInfoCmd[item];
            });
          }
          const invoiceTitleCmd = {
            ...invoiceInfoForm,
            distributorId,
            contactAddressCmd: {
              addressDetail: invoiceInfoForm.addressDetail,
              cityId: invoiceInfoForm.areaCodes?.[0] || undefined,
              provinceId: invoiceInfoForm.areaCodes?.[1] || undefined,
              zoneId: invoiceInfoForm.areaCodes?.[2] || undefined,
            },
          };
          const requestFun = id ? updateInfo : createInfo;
          const messageText = id ? '修改' : '新增';
          const params = {
            distributorContractInfoCmd,
            invoiceTitleCmd,
            distributorBankCardIdList
          };
          requestFun(params)
            .then(() => {
              this.$message({
                message: `${messageText}成功`,
                type: 'success'
              });
              this.backPage();
            })
            .finally(() => {
              this.formLoading = false;
            });
        })
        .catch(() => {
          this.formLoading = false;
          console.log('表单校验未通过');
        });
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
::v-deep {
  .el-table th > .cell {
    white-space: pre-line;
  }
  .el-dialog .el-table {
    margin: 0;
  }
}
.tips-text {
  line-height: 150%;
}
.btn-div {
  margin-bottom: 20px;
}
.contractInfo {
  padding: 0 20px;
  ::v-deep {
    .el-input,
    .el-select,
    .el-textarea {
      width: 280px;
    }
  }

  .el-form-item {
    margin-bottom: 22px;
  }
  .link {
    color: var(--color-primary);
    margin: 0 20px;
  }
}
.address-select {
  width: 100% !important;

  ::v-deep {
    .el-input,
    .el-select,
    .el-textarea {
      width: 100%;
    }
  }
}
.custom-label {
  li {
    line-height: 20px;
  }
}
.title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}
.tips-title {
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 30px;
  margin-left: 10px;
  &--color {
    color: rgba(92, 90, 90, 0.67);
  }
}
.form-title {
  display: flex;
  align-items: center;
  padding: 12px 0;
  &__text {
    display: flex;
    align-items: center;
    min-width: 120px;
    font-size: 14px;
    color: var(--color-text-primary);
    font-weight: bold;
    &:before {
      content: '';
      height: 12px;
      width: 2px;
      background: var(--color-primary);
      margin-right: 4px;
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  &__box-wrap {
    line-height: 36px;
    margin-right: 10px;
  }
  &__textbox {
    font-size: 14px;
  }
  &__title {
    font-size: 14px;
  }
}
.area-width {
  width: 270px;
  margin-right: 20px;
}
.textBox {
  text-align: justify;
  .mainBox {
    padding: 0 10px 50px 10px;
    .mainBox-title {
      font-weight: 600;
      font-size: 14px;
      margin: 5px 0;
      text-align: center;
      display: block;
    }
  }
}
</style>
